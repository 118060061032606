import React, { useEffect, useRef, useState } from "react";
import { TextField } from "@material-ui/core";

export default function GeoLocation({ onChange, disabled }: GeoLocationProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState("");
  const [
    autoComplete,
    setAutoComplete,
  ] = useState<google.maps.places.Autocomplete>();
  useEffect(() => {
    if (inputRef.current && autoComplete === undefined) {
      const auto = new window.google.maps.places.Autocomplete(inputRef.current);
      auto.addListener("place_changed", () => {
        const place = auto.getPlace();
        onChange?.({
          address: place.formatted_address ?? "",
          latitude: place.geometry?.location.lat() ?? 0,
          longitude: place.geometry?.location.lng() ?? 0,
        });
        setValue("");
      });
      setAutoComplete(auto);
    }
  }, [inputRef, autoComplete, onChange]);

  return (
    <TextField
      value={value}
      onChange={event => setValue(event.target.value)}
      fullWidth
      disabled={disabled}
      inputRef={inputRef}
      type="text"
    />
  );
}

type GeoLocationProps = {
  disabled?: boolean;
  onChange?: (place: {
    latitude: number;
    longitude: number;
    address: string;
  }) => void;
};
