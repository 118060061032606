import React from "react";
import "./App.css";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import ShopList from "./comps/ShopList";
import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Shop from "./comps/Shop";
import Login from "./comps/Login";
import { green, purple } from "@material-ui/core/colors";

const httpLink = createHttpLink({
  uri: /localhost/i.test(window.location.host)
    ? "http://localhost:4000/graphql"
    : "https://api.shop-fair.de/graphql",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("api-key");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: green[500],
    },
    secondary: {
      main: purple[600],
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Login>
          <Router>
            <CssBaseline />
            <Route path="/" exact component={ShopList} />
            <Route path="/:id" exact component={Shop} />
          </Router>
        </Login>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
