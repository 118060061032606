import React, { useState } from "react";
import { TextField } from "@material-ui/core";

export default function Login({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("api-key")
  );

  if (token) {
    return <>{children}</>;
  }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TextField
        label="API-KEY"
        onChange={({ target: { value } }) => {
          localStorage.setItem("api-key", value);
          setToken(value);
        }}
      />
    </div>
  );
}
