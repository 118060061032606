import React, { useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import {
  List,
  ListItem,
  Paper,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Grid,
  TextField,
  IconButton,
  Tooltip,
  Typography,
  Chip,
  Box,
} from "@material-ui/core";

import { Add, ExitToApp, Image } from "@material-ui/icons";

import { Link, useHistory } from "react-router-dom";
import { ShopType } from "../graphql/shop";

interface ShopData {
  shops: ShopType[];
}

export default function ShopList(): JSX.Element {
  const [search, setSearch] = useState("");
  const history = useHistory();

  const { data, error } = useQuery<ShopData>(gql`
    query shops {
      shops(orderBy: { name: asc }) {
        name
        image
        country
        id
        categories {
          name
        }
      }
    }
  `);

  const [addShop, { data: mutationData }] = useMutation(
    gql`
      mutation addShop {
        createShop(data: { name: "Neuer Shop" }) {
          id
        }
      }
    `,
    { refetchQueries: ["shops"] }
  );

  if (mutationData) {
    history.push(`/${mutationData.createShop.id}`);
  }

  if (error) {
    return <>{error.message}</>;
  }

  const shops = (data?.shops ?? []).filter(
    ({ name }) => search === "" || new RegExp(search, "i").test(name)
  );

  return (
    <Paper>
      <Box padding={4}>
        <Grid
          container
          spacing={2}
          justify="space-between"
          alignItems="center"
          alignContent="center"
        >
          <Grid item>
            <img
              src="/Logo.png"
              width="48"
              height="48"
              alt="JOJO MAUS SIEHT GUT AUS"
            />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <TextField
              value={search}
              placeholder="Suche"
              fullWidth
              onChange={({ target: { value } }) => setSearch(value)}
            />
          </Grid>
          <Grid item>
            <Chip label={`${shops.length}`} />
          </Grid>
          <Grid item>
            <Tooltip title="Shop hinzufügen">
              <IconButton onClick={() => addShop()}>
                <Add />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Abmelden">
              <IconButton
                onClick={() => {
                  localStorage.removeItem("api-key");
                  window.location.reload();
                }}
              >
                <ExitToApp />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <List>
        {shops.map(({ name, id, country, image, categories }, index) => (
          <ListItem
            divider={index + 1 < (data?.shops.length || 0)}
            button
            component={props => <Link to={`/${id}`} {...props} />}
          >
            <ListItemAvatar>
              {Boolean(image) ? (
                <Avatar src={image} alt="name" />
              ) : (
                <Avatar>
                  <Image />
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={name}
              secondary={
                <>
                  <Typography variant="subtitle1">{country}</Typography>
                  {categories.length > 0 && (
                    <>
                      <Typography variant="subtitle2">
                        {categories.map(({ name }) => name).join(", ")}
                      </Typography>
                    </>
                  )}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}
