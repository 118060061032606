import { gql } from "@apollo/client";
import { Location } from "./location";
export const shopQuery = gql`
  query singleShop($id: Int!) {
    shop(where: { id: $id }) {
      id
      image
      name
      description
      country
      city
      cookieConsentSelector
      url
      vegan
      categories {
        id
        name
      }
      labels {
        id
        name
        imagePath
      }
      locations {
        id
        address
        latitude
        longitude
      }
    }
    categories {
      id
      name
    }
    labels {
      id
      name
    }
  }
`;

export const fetchImageMutation = gql`
  mutation fetchImage($url: String!, $cookieSelector: String!) {
    fetchImage(url: $url, cookieSelector: $cookieSelector)
  }
`;
export const updateShopMutation = gql`
  mutation updateShop($id: Int!, $data: ShopUpdateInput!) {
    updateShop(where: { id: $id }, data: $data) {
      id
    }
  }
`;

export type ShopType = {
  id: number;
  name: string;
  description?: string;
  image?: string;
  country?: string;
  city?: string;
  cookieConsentSelector?: string;
  url?: string;
  categories: CategoryType[];
  labels: LabelType[];
  locations: Location[];
  vegan: VeganEnumType;
};

export enum VeganEnumType {
  no = 0,
  hasVeganProducts = 1,
  is100PercentVegan = 2,
}

export type CategoryType = {
  id?: number;
  name: string;
};
export type LabelType = {
  id?: number;
  name: string;
  imagePath?: string;
};
