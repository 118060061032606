import { gql } from "@apollo/client";

export const createLocationMutation = gql`
  mutation createLocation($data: LocationCreateInput!) {
    createLocation(data: $data) {
      id
    }
  }
`;

export const deleteLocationMutation = gql`
  mutation createLocation($id: Int!) {
    deleteLocation(where: { id: $id }) {
      id
    }
  }
`;

export type Location = {
  id: number;
  address: string;
  latitude: number;
  longitude: number;
};
