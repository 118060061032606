import React, { ChangeEvent, useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Divider,
  Grid,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
  Modal,
  makeStyles,
  CircularProgress,
  List,
  ListItemText,
  ListItem,
  Paper,
  Box,
} from "@material-ui/core";
import {
  Alert,
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import { useHistory, useParams } from "react-router-dom";
import {
  ChevronLeft,
  Delete,
  DeleteOutline,
  ImageOutlined,
  Save,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import {
  CategoryType,
  shopQuery,
  ShopType,
  updateShopMutation,
  fetchImageMutation,
  VeganEnumType,
  LabelType,
} from "../graphql/shop";

import GeoLocation from "./GeoLocation";
import {
  createLocationMutation,
  deleteLocationMutation,
  Location,
} from "../graphql/location";

const useStyles = makeStyles({
  modal: {
    padding: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: 0,
    "& img": {
      outline: 0,
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
});

export default function Shop(): JSX.Element {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [showDelete, setShowDelete] = useState(false);
  const [now, setNow] = useState(Date.now());
  const [imagePreview, setImagePreview] = useState(false);

  const [shop, setShop] = useState<ShopType>();

  const { data, loading, refetch } = useQuery<{
    shop: ShopType;
    categories: CategoryType[];
    labels: LabelType[];
  }>(shopQuery, {
    variables: { id: parseInt(id, 10) },
  });

  const [deleteShop, { data: deleteData }] = useMutation(
    gql`
      mutation deleteShop($id: Int!) {
        deleteShop(where: { id: $id }) {
          id
        }
      }
    `,
    { variables: { id: parseInt(id, 10) }, refetchQueries: ["shops"] }
  );

  const [updateShop, { error: updateError }] = useMutation(updateShopMutation, {
    refetchQueries: [
      { query: shopQuery, variables: { id: parseInt(id, 10) } },
      "shops",
    ],
  });

  const [
    addLocation,
    { error: addLocationError, loading: loadingAddLocation },
  ] = useMutation<Location>(createLocationMutation);
  const [
    deleteLocation,
    { error: deleteLocationError, loading: loadingDeleteLocation },
  ] = useMutation<Location, { id: number }>(deleteLocationMutation);

  const [fetchImage, { loading: loadingFetchImage }] = useMutation<
    boolean,
    { url: string; cookieSelector: string }
  >(fetchImageMutation, {
    variables: {
      url: shop?.url ?? "",
      cookieSelector: shop?.cookieConsentSelector ?? "",
    },
  });

  const save = () => {
    if (shop === undefined) {
      return;
    }
    const newData = Object.entries(shop).reduce<any>((result, [key, value]) => {
      if (
        [
          "name",
          "country",
          "url",
          "city",
          "cookieConsentSelector",
          "description",
          "vegan",
        ].includes(key)
      ) {
        result[key] = { set: value || null };
      }

      if (key === "categories") {
        const categories = {
          connect: (value as Array<{ id?: number; name: string }>)
            .filter(({ id }) => id !== undefined)
            .map(({ id }) => ({ id })),
          create: (value as Array<{ id?: number; name: string }>)
            .filter(({ id }) => id === undefined)
            .map(({ name }) => ({ name })),
          disconnect: (
            data?.shop?.categories as Array<{
              id?: number;
              name: string;
            }>
          )
            .filter(
              ({ id }) =>
                shop.categories.find(cat => cat.id === id) === undefined
            )
            .map(({ id }) => ({ id })),
        };
        result["categories"] = categories;
      }

      if (key === "labels") {
        const labels = {
          connect: (value as Array<{ id?: number; name: string }>)
            .filter(({ id }) => id !== undefined)
            .map(({ id }) => ({ id })),
          create: (value as Array<{ id?: number; name: string }>)
            .filter(({ id }) => id === undefined)
            .map(({ name }) => ({ name })),
          disconnect: (
            data?.shop?.labels as Array<{
              id?: number;
              name: string;
            }>
          )
            .filter(
              ({ id }) => shop.labels.find(cat => cat.id === id) === undefined
            )
            .map(({ id }) => ({ id })),
        };
        result["labels"] = labels;
      }

      return result;
    }, {});

    updateShop({
      variables: { id: parseInt(id, 10), data: newData },
    });
  };

  if (deleteData) {
    history.push("/");
  }

  useEffect(() => {
    setShop(data?.shop);
    setNow(Date.now());
  }, [data]);

  if (loading) {
    return <>Lade..</>;
  }

  if (shop === undefined) {
    return <>Fehler</>;
  }

  const { country, name, image, url } = shop;
  const textFieldProps = (name: keyof ShopType, label: string) => ({
    value: shop[name],
    label,
    fullWidth: true,
    onChange: ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setShop({ ...shop, [name]: value });
    },
  });

  return (
    <>
      {Boolean(updateError) && <Alert>{updateError?.message}</Alert>}
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Link to="/">
            <IconButton>
              <ChevronLeft />
            </IconButton>
          </Link>
        </Grid>
        {Boolean(image) && (
          <Grid item>
            <img
              src={`${image}?_=${now}`}
              alt={name}
              width="80"
              onClick={() => setImagePreview(true)}
            />
            <Modal
              open={imagePreview}
              onClose={() => setImagePreview(false)}
              className={classes.modal}
            >
              <img src={`${image}?_=${now}`} alt={name} />
            </Modal>
          </Grid>
        )}

        <Grid item xs={12} sm="auto" style={{ flexGrow: 1 }}>
          <Typography variant="h2">{name}</Typography>
          <Typography variant="caption" paragraph>
            {country}
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={2}
            alignContent="center"
            alignItems="center"
            justify="space-between"
          >
            {Boolean(url) && (
              <Grid item>
                <Button
                  href={url ?? ""}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Zur Website
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                startIcon={
                  loadingFetchImage ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <ImageOutlined />
                  )
                }
                onClick={async () => {
                  await fetchImage();
                  setNow(Date.now());
                }}
                variant="outlined"
                color="primary"
                disabled={loadingFetchImage}
              >
                <Tooltip title="Neuen Screenshot anfordern">
                  <span>Foto aktualisieren</span>
                </Tooltip>
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<Save />}
                onClick={() => save()}
                variant="outlined"
                color="primary"
                disabled={JSON.stringify(shop) === JSON.stringify(data?.shop)}
              >
                <Tooltip title="Änderungen speichern">
                  <span>Speichern</span>
                </Tooltip>
              </Button>
            </Grid>
            <Grid item>
              <Tooltip title={`${name} löschen`}>
                <IconButton
                  color="secondary"
                  onClick={() => setShowDelete(true)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
              <Dialog open={showDelete} onClose={() => setShowDelete(false)}>
                <DialogTitle>{`Lösche ${name}`}</DialogTitle>
                <DialogContent>
                  <DialogContentText>{`Lösche ${name}`}</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setShowDelete(false)}>Nö</Button>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => deleteShop()}
                  >
                    Jetzt löschen
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <br />
      <br />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          <TextField {...textFieldProps("name", "Name")} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField {...textFieldProps("country", "Land")} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField {...textFieldProps("city", "Stadt")} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField {...textFieldProps("url", "Url")} />
        </Grid>
      </Grid>
      <br />
      <br />
      <Divider />
      <br />
      <br />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} lg={3}>
          <Autocomplete
            multiple
            options={(data?.categories ?? []) as { name: string; id: number }[]}
            filterSelectedOptions
            getOptionSelected={option =>
              (shop?.categories ?? []).find(
                ({ id }) => option.id === id && option.id !== undefined
              ) !== undefined
            }
            onChange={(_, options) => {
              setShop({
                ...shop,
                categories: options.map(option => {
                  if (typeof option === "string") {
                    const id = data?.categories.find(
                      ({ name }: { name: string }) => name === option
                    )?.id;

                    return { name: option, id };
                  } else {
                    const { name, id } = option;
                    return { name, id };
                  }
                }),
              });
            }}
            freeSolo
            getOptionLabel={({ name }: { name: string; id: number }) => name}
            value={(shop?.categories ?? []) as { name: string; id: number }[]}
            renderInput={params => (
              <TextField {...params} label="Kategorien" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Autocomplete
            multiple
            options={(data?.labels ?? []) as { name: string; id: number }[]}
            filterSelectedOptions
            getOptionSelected={option =>
              (shop?.labels ?? []).find(
                ({ id }) => option.id === id && option.id !== undefined
              ) !== undefined
            }
            onChange={(_, options) => {
              setShop({
                ...shop,
                labels: options.map(option => {
                  if (typeof option === "string") {
                    const id = data?.labels.find(
                      ({ name }: { name: string }) => name === option
                    )?.id;

                    return { name: option, id };
                  } else {
                    const { name, id } = option;
                    return { name, id };
                  }
                }),
              });
            }}
            freeSolo
            getOptionLabel={({ name }: { name: string; id: number }) => name}
            value={(shop?.labels ?? []) as { name: string; id: number }[]}
            renderInput={params => (
              <TextField {...params} label="Labels" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ToggleButtonGroup
            exclusive
            value={shop?.vegan ?? "no"}
            onChange={(_, value: VeganEnumType) => {
              console.log({ value, shop });

              setShop({ ...shop, vegan: value });
            }}
          >
            <ToggleButton value="no">Nope</ToggleButton>
            <ToggleButton value="hasVeganProducts">
              hat vegane Produkte
            </ToggleButton>
            <ToggleButton value="is100PercentVegan">100% vegan</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            {...textFieldProps("cookieConsentSelector", "CookieSelector")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            {...textFieldProps("description", "Beschreibung")}
            multiline
            rows={4}
            rowsMax={20}
          />
        </Grid>
        <Grid item xs={12}>
          <GeoLocation
            disabled={loadingAddLocation}
            onChange={async place => {
              await addLocation({
                variables: {
                  data: {
                    address: place.address,
                    latitude: place.latitude,
                    longitude: place.longitude,
                    Shop: { connect: { id: shop.id } },
                  },
                },
              });
              refetch();
            }}
          />{" "}
        </Grid>
        {Boolean(addLocationError) && (
          <Alert severity="error">{addLocationError?.message}</Alert>
        )}
        {Boolean(deleteLocationError) && (
          <Alert severity="error">{deleteLocationError?.message}</Alert>
        )}
        <Grid item xs={12}>
          <Paper>
            <Box padding={2}>
              <List>
                {shop.locations.map(({ address, id }) => (
                  <>
                    <ListItem>
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                      >
                        <ListItemText>{address}</ListItemText>
                        <IconButton
                          disabled={loadingDeleteLocation}
                          onClick={async () => {
                            await deleteLocation({ variables: { id } });
                            refetch();
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Grid>
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
